import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';

const PageSpinner = () => (
  <div className="h-full flex items-center justify-center">
    <FontAwesomeIcon icon={faSpinnerThird} spin className="text-primary h-8 w-8" />
  </div>
);

export default PageSpinner;
