const LogoSimple = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="239" height="254" fill="none" viewBox="0 0 239 254" {...props}>
    <g clipPath="url(#clip0_13_4)">
      <path
        fill="#1F0E32"
        d="M30.625 109.362v82.78c0 19.24 5.104 33.859 15.312 43.859C56.145 246 70.575 251 89.227 251H138v-54.301h-24.197c-6.302 0-10.839-1.392-13.611-4.177-2.773-2.785-4.16-7.341-4.16-13.67v-69.49H138V55.061H96.033V0H30.625v55.06H0v54.302h30.625z"
      ></path>
      <path
        fill="#7F56D9"
        d="M173.83 190.571C166.61 197.619 163 206.429 163 217c0 10.571 3.61 19.381 10.83 26.429C181.05 250.476 190.107 254 201 254s19.95-3.524 27.17-10.571C235.39 236.381 239 227.571 239 217c0-10.571-3.61-19.381-10.83-26.429C220.95 183.524 211.893 180 201 180s-19.95 3.524-27.17 10.571z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_13_4">
        <path fill="#fff" d="M0 0H239V254H0z"></path>
      </clipPath>
    </defs>
  </svg>
);

export default LogoSimple;
