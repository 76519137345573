/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';
import { UserProfile } from '../api';

export const useAnalytics = () => {
  const track = useCallback((event: string, properties?: Record<string, string | number | boolean>) => {
    console.log('track', event, properties);
  }, []);

  const identify = useCallback((user: UserProfile) => {
    if ((window as any).Beacon) {
      (window as any).Beacon('identify', {
        name: user ? `${user.firstName} ${user.lastName}`.trim() : undefined,
        email: user.email,
        avatar: user?.avatarUrl,
      });
    }
  }, []);

  return { track, identify };
};
