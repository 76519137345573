import { Formik } from 'formik';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Trans, useTranslation } from 'react-i18next';
import { useSearchParam } from 'react-use';
import * as Yup from 'yup';
import { config } from '../../../../config';
import { useRequestUserPasswordReset } from '../../../../hooks';
import { ReturnToSignInLink } from '../../components';
import { ResetPasswordForm } from './ResetPasswordForm';

type FormValues = {
  email: string;
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
});

const ResetPasswordPage = () => {
  const { t } = useTranslation();
  const email = useSearchParam('email') ?? '';
  const { mutate: request, isSuccess, error } = useRequestUserPasswordReset();
  const [usedEmail, setUsedEmail] = useState('');
  const initialValues: FormValues = {
    email,
  };

  const submit = async ({ email }: FormValues) =>
    request({ email, client: config.clientApp }, { onSuccess: (_, { email }) => setUsedEmail(email) });

  return (
    <>
      <Helmet title={t('auth.reset-password.page-title')} />

      {isSuccess ? (
        <div className="text-center">
          <h1 className="mb-2 text-2xl font-semibold text-slate-900">
            <Trans i18nKey="auth.reset-password.heading" />
          </h1>
          <p className="mb-6 px-2 text-sm text-slate-500">{t('auth.reset-password.success-description', { email: usedEmail })}</p>
        </div>
      ) : (
        <>
          <div className="text-center">
            <h1 className="mb-2 text-2xl font-semibold text-slate-900">
              <Trans i18nKey="auth.reset-password.heading" />
            </h1>
            <p className="mb-6 px-2 text-sm text-slate-500">
              <Trans i18nKey="auth.reset-password.description" />
            </p>
          </div>

          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit}>
            {(props) => <ResetPasswordForm error={error} {...props} />}
          </Formik>
        </>
      )}

      <ReturnToSignInLink />
    </>
  );
};

export default ResetPasswordPage;
