import { ReturnToSignInLink } from '../../components';
import * as Yup from 'yup';
import { useResetUserPassword } from '../../../../hooks';
import { ChangePasswordPageData } from './types';
import { Formik, FormikHelpers } from 'formik';
import { ChangePasswordForm } from './ChangePasswordForm';

type FormValues = {
  newPassword: string;
  confirmPassword: string;
};

type Props = {
  data: ChangePasswordPageData;
};

const validationSchema = Yup.object().shape({
  newPassword: Yup.string().required('Please enter a new password'),
  confirmPassword: Yup.string()
    .required('Please confirm your new password')
    .oneOf([Yup.ref('newPassword'), ''], 'Passwords must match'),
});

export const ChangePassword = ({ data: { email, token } }: Props) => {
  const { mutate: changePassword, isSuccess, error } = useResetUserPassword();

  const initialValues: FormValues = {
    newPassword: '',
    confirmPassword: '',
  };

  const submit = ({ newPassword }: FormValues, { setFieldValue }: FormikHelpers<FormValues>) =>
    changePassword(
      { email, token, newPassword },
      {
        async onError() {
          await setFieldValue('newPassword', '');
        },
      },
    );

  return (
    <>
      {isSuccess ? (
        <div className="text-center">
          <h1 className="mb-2 text-2xl font-semibold text-slate-900">Password changed</h1>
          <p className="mb-6 px-2 text-sm text-slate-500">Your password has been changed. You can now sign in with your new password.</p>
        </div>
      ) : (
        <>
          <div className="text-center">
            <h1 className="mb-2 text-2xl font-semibold text-slate-900">Set a new password</h1>
            <p className="mb-6 px-2 text-sm text-slate-500">
              Enter a new password for your account. You'll be able to use this password to sign in to Timewave.
            </p>
          </div>

          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit}>
            {(props) => <ChangePasswordForm error={error} {...props} />}
          </Formik>
        </>
      )}

      <ReturnToSignInLink />
    </>
  );
};
