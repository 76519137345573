import { Await, useLoaderData } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Suspense } from 'react';
import { DataErrorScreen, PageSpinner } from '../../ui';
import { useTranslation } from 'react-i18next';

type Props<TData> = {
  title?: string;
  titleKey?: string;
  Component: React.ComponentType<{ data: TData }>;
  ErrorElement?: React.ReactNode;
};

const AsyncPage = <T,>({ title, titleKey, Component, ErrorElement }: Props<T>) => {
  const { t } = useTranslation();
  const loader = useLoaderData() as { data: T };

  const helmetTitle = titleKey ? t(titleKey) : title;

  return (
    <>
      {helmetTitle && <Helmet title={helmetTitle} />}
      <Suspense fallback={<PageSpinner />}>
        <Await resolve={loader.data} errorElement={ErrorElement ?? <DataErrorScreen />}>
          {(data: T) => <Component data={data} />}
        </Await>
      </Suspense>
    </>
  );
};

export default AsyncPage;
