import axios, { AxiosProgressEvent } from 'axios';

export const axiosGet =
  <TResponse>(url: string) =>
  ({ signal }: { signal?: AbortSignal } = {}) =>
    axios.get<TResponse>(url, { signal }).then((res) => res.data);

export const axiosPost = <TRequest, TResponse = void>(url: string, request: TRequest) =>
  axios.post<TResponse>(url, request).then((res) => res.data);

export const axiosPostMultiPart = <TRequest, TResponse = void>(
  url: string,
  request: TRequest,
  onUploadProgress?: (event: AxiosProgressEvent) => void,
) => axios.post<TResponse>(url, request, { headers: { 'Content-Type': 'multipart/form-data' }, onUploadProgress }).then((res) => res.data);

export const axiosPut = <TRequest, TResponse = void>(url: string, request: TRequest) =>
  axios.put<TResponse>(url, request).then((res) => res.data);

export const axiosPatch = <TRequest, TResponse = void>(url: string, request: TRequest) =>
  axios.patch<TResponse>(url, request).then((res) => res.data);

export const axiosDelete = <TResponse = void>(url: string) => axios.delete<TResponse>(url).then((res) => res.data);
