import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { useHttpOptions } from '../../../hooks';
import { ChangePasswordPage, LoginPage, ResetPasswordPage, useChangePasswordPageLoader } from '../../../modules/Auth';
import { AuthLayout } from '../../layout';
import { FullPageSpinner } from '../../ui';

export const GuestApp = () => {
  const changePasswordPageLoader = useChangePasswordPageLoader();

  const router = createBrowserRouter([
    {
      path: '/auth/*',
      element: <AuthLayout />,
      children: [
        {
          path: 'login',
          element: <LoginPage />,
        },
        {
          path: 'reset-password',
          element: <ResetPasswordPage />,
        },
        {
          path: 'change-password/:token',
          element: <ChangePasswordPage />,
          loader: changePasswordPageLoader,
        },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/auth/login" />,
    },
  ]);

  useHttpOptions();

  return <RouterProvider router={router} fallbackElement={<FullPageSpinner />} />;
};
