import { createContext, PropsWithChildren, useMemo } from 'react';
import { useLocalStorage } from 'react-use';
import { isNumber } from 'lodash-es';
import { NumberParam, useQueryParam, useQueryParams } from 'use-query-params';

type PaginationContextValue = {
  pageIndex: number;
  pageSize: number;
  getPageCount: (totalRows: number) => number;
  changePageIndex: (pageIndex: number) => void;
  changePageSize: (pageSize: number) => void;
};

const PaginationContext = createContext<PaginationContextValue>({
  pageIndex: 0,
  pageSize: 10,
  getPageCount: () => 0,
  changePageIndex: () => void 0,
  changePageSize: () => void 0,
} as PaginationContextValue);

type Props = {
  id?: string;
};

const PaginationProvider = ({ id = 'global', children }: PropsWithChildren<Props>) => {
  const perPageParam = useQueryParam('perPage', NumberParam);
  const [perPageStoredValue, setPerPageStoredValue] = useLocalStorage(
    `pagination-per-page-${id}`,
    isNumber(Number(perPageParam)) && Number(perPageParam) > 0 ? Number(perPageParam) : 10,
  );
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    perPage: NumberParam,
  });

  const pageIndex = query.page ? query.page - 1 : 0;
  const pageSize = query.perPage ? query.perPage : perPageStoredValue!;

  const actions = useMemo(() => {
    const getPageCount = (totalRows: number) => Math.ceil(totalRows / pageSize);

    const changePageIndex = (pageIndex: number) => {
      setQuery({ page: pageIndex + 1 }, 'replaceIn');
    };

    const changePageSize = (pageSize: number) => {
      setQuery({ perPage: pageSize }, 'replace');
      setPerPageStoredValue(pageSize);
    };

    return { pageIndex, pageSize, getPageCount, changePageIndex, changePageSize };
  }, [pageIndex, pageSize, setPerPageStoredValue, setQuery]);

  return <PaginationContext.Provider value={actions}>{children}</PaginationContext.Provider>;
};

export { PaginationContext, PaginationProvider };
