export enum QueryKeys {
  ADDRESS_AUTOCOMPLETE = 'address_autocomplete',
  DASHBOARD_DATA = 'dashboard_data',
  RESET_PASSWORD_TOKEN_VERIFICATION = 'reset_password_token_verification',
  SKILLS = 'skills',
  STATES = 'states',
  TIME_ZONE_BY_COORDINATES = 'time_zone_by_coordinates',
  USERS = 'users',
  VENUES = 'venues',
}
