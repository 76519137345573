import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { RootState } from '.';
import { UserPreferences, UserProfile } from '../api';
import { flatten, groupBy, isNil, map } from 'lodash-es';

export interface AuthSliceState {
  token?: Nullable<string>;
  user: Nullable<UserProfile>;
  context?: {
    accountId: number;
    roleId: number;
  };
}

const authSlice = createSlice({
  name: 'auth',
  initialState: { token: null, user: null } as AuthSliceState,
  reducers: {
    setToken(state, { payload: token }: PayloadAction<Nullable<string>>) {
      state.token = token;
    },

    clearToken(state) {
      state.token = null;
    },

    setUser(state, { payload: user }: PayloadAction<Nullable<UserProfile>>) {
      state.user = user;
    },

    setContext(state, { payload: { accountId, roleId } }: PayloadAction<{ accountId?: number; roleId?: number }>) {
      state.context = {
        accountId: accountId ?? state.context!.accountId,
        roleId: roleId ?? state.context!.roleId,
      };
    },

    clearContext(state) {
      state.context = undefined;
    },

    updateUserProfile(state, { payload: user }: PayloadAction<UserProfile>) {
      state.user = { ...state.user, ...user };
    },

    updateUserPreferences(state, { payload: preferences }: PayloadAction<Partial<UserPreferences>>) {
      if (state.user) {
        state.user.preferences = { ...state.user.preferences, ...preferences };
      }
    },

    updateAccountSettings(
      state,
      {
        payload: { accountId, settings },
      }: PayloadAction<{
        accountId: number;
        settings: {
          name: string;
        };
      }>,
    ) {
      if (!state.user) {
        return;
      }

      state.user.accounts = state.user.accounts.map((account) => {
        if (account.id === accountId) {
          return { ...account, ...settings };
        }

        return account;
      });
    },
  },
});

const selectAuth = (state: RootState) => state.auth;

export const getAuthToken = createSelector(selectAuth, ({ token }) => token);
export const getCurrentUser = createSelector(selectAuth, ({ user }) => user);

export const useCurrentUser = () => useSelector(getCurrentUser)!;

const getIsLoggedIn = createSelector(getAuthToken, getCurrentUser, (token, user) => !isNil(token) && !isNil(user));
export const useIsLoggedIn = () => useSelector(getIsLoggedIn);

export const usePreferences = () => useSelector(getCurrentUser)!.preferences;

export const useLocale = () => useSelector(getCurrentUser)?.preferences.locale ?? 'en-US';

const getInitialAuthContext = createSelector(selectAuth, ({ context }) => context);
export const useInitialAuthContext = () => useSelector(getInitialAuthContext);
const getAccounts = createSelector(selectAuth, ({ user }) => {
  if (!user) {
    return [];
  }

  return user.accounts;
});
const getGroupedAccounts = createSelector(getAccounts, (accounts) => {
  const groupedAccounts = groupBy(accounts, 'id');

  return flatten(
    map(groupedAccounts, (accounts) => ({
      id: accounts[0].id,
      name: accounts[0].name,
      logoUrl: accounts[0].logoUrl,
      roles: accounts.map((account) => account.role),
    })),
  );
});
export const useAccounts = () => useSelector(getAccounts);
export const useGroupedAccounts = () => useSelector(getGroupedAccounts);
export const getAuthContext = createSelector(selectAuth, getAccounts, ({ user, context }, accounts) => {
  if (accounts.length > 1) {
    return { accountId: context?.accountId, roleId: context?.roleId };
  }

  if (!user?.accounts || user.accounts.length === 0) {
    return { accountId: null, roleId: null };
  }

  return { accountId: user?.accounts[0].id, roleId: user?.accounts[0].role.id };
});
export const useAuthContext = () => useSelector(getAuthContext);
export const getCurrentAccount = createSelector(getAuthContext, getAccounts, ({ accountId }, accounts) =>
  accounts.find((a) => a.id === accountId),
);
export const useCurrentAccount = () => useSelector(getCurrentAccount)!;
export const getCurrentRole = createSelector(
  getAuthContext,
  getAccounts,
  ({ accountId, roleId }, accounts) => accounts.find((a) => a.id === accountId && a.role.id === roleId)?.role,
);
export const useCurrentRole = () => useSelector(getCurrentRole);

export const useAccountId = () => useSelector(getCurrentAccount)?.id;

export const { setToken, clearToken, setUser, setContext, clearContext, updateUserProfile, updateUserPreferences, updateAccountSettings } =
  authSlice.actions;

export default authSlice;
