import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage, Form, FormikProps } from 'formik';
import { ErrorResponse } from '../../../../api';
import { InputField } from '../../../../components';
import { SubmitButton } from '../../../../components/ui';

type Props<T> = FormikProps<T> & {
  error: Nullable<ErrorResponse>;
};

export const ChangePasswordForm = <T,>({ error, isSubmitting }: Props<T>) => (
  <>
    <Form noValidate spellCheck={false} aria-label="Sign in to Timewave" className="mb-4 flex flex-col gap-4">
      <div className="flex flex-col gap-1.5">
        <label htmlFor="password" id="password" className="w-full text-sm font-medium text-slate-700">
          Password
        </label>
        <InputField type="password" placeholder="••••••••" name="newPassword" autoComplete="new-password" className="w-full" />
        <ErrorMessage name="newPassword" component="div" className="text-sm text-error-500" />
      </div>

      <div className="flex flex-col gap-1.5">
        <label htmlFor="password" id="password" className="w-full text-sm font-medium text-slate-700">
          Confirm password
        </label>
        <InputField type="password" placeholder="••••••••" name="confirmPassword" autoComplete="new-password" className="w-full" />
        <ErrorMessage name="confirmPassword" component="div" className="text-sm text-error-500" />
      </div>

      <SubmitButton type="submit" isSubmitting={isSubmitting} className="u-btn u-btn--primary w-full">
        Change password
      </SubmitButton>
    </Form>

    {error && (
      <div className="flex rounded-xl border border-error-300 bg-error-25 p-4 text-sm text-error-700">
        <div className="mr-3">
          <div className="inline-flex rounded-full border border-error-300 p-0.5 ring-1 ring-error-300 ring-opacity-50 ring-offset-2">
            <FontAwesomeIcon icon={faExclamationCircle} className="h-4 w-auto text-error-600" />
          </div>
        </div>
        <div>
          <div className="font-semibold">Password could not be changed</div>
          <div>An error has occurred. Please try again later.</div>
        </div>
      </div>
    )}
  </>
);
