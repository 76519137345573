import axios, { AxiosError, AxiosRequestHeaders, AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { getAuthToken } from '../store/auth.slice';
import { useAuth } from './useAuth';

export const useHttpOptions = () => {
  const token = useSelector(getAuthToken);
  const { i18n } = useTranslation();
  const { logout } = useAuth();

  axios.defaults.headers.common = {
    Accept: 'application/json',
    'Accept-Language': i18n.language,
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token ?? ''}`,
  };

  axios.interceptors.request.use((request) => {
    const traceId = uuid();
    const spanId = uuid();

    if (!request.headers) {
      request.headers = {} as AxiosRequestHeaders;
    }

    request.headers['Trace-ID'] = traceId;
    request.headers['Parent-ID'] = spanId;

    return request;
  });

  axios.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
      if (error?.response?.status === 401) {
        logout();
        window.location.href = '/auth/login';
      }

      if (error.response) {
        return Promise.reject(error.response.data);
      }

      return Promise.reject(error);
    },
  );

  return {
    headers: {
      Accept: 'application/json',
      'Accept-Language': i18n.language,
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : undefined,
    },
    interceptors: {
      response: ({ response }: { response: AxiosResponse }) => {
        if (response.status === 401) {
          logout();
          window.location.href = '/auth/login';
        }
        return response;
      },
    },
  };
};
