import { Field, FieldAttributes, useField } from 'formik';
import { cn } from '../../../../utils';

const InputField = <T,>({ name, className, onBlur, ...rest }: FieldAttributes<T>) => {
  const [{ onBlur: onFormikBlur }] = useField(name);

  return (
    <Field
      name={name}
      className={cn('u-input', className)}
      onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-argument
        onBlur?.(e as any);
        onFormikBlur(e);
      }}
      {...rest}
    />
  );
};

export default InputField;
