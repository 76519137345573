import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, FormikProps } from 'formik';
import { Trans } from 'react-i18next';
import { InputField, SubmitButton } from '../../../../components';
import { ResetPasswordLink } from './ResetPasswordLink';

type Props<T> = FormikProps<T> & {
  error: boolean;
  errorMessage: string;
};

export const LoginForm = <T,>({ error, errorMessage, isSubmitting }: Props<T>) => (
  <>
    <Form noValidate spellCheck={false} aria-label="Sign in to Timewave" className="mb-4 flex flex-col gap-4">
      <div className="flex flex-col gap-1.5">
        <label htmlFor="email" className="w-full text-sm font-medium text-slate-700">
          <Trans i18nKey="auth.login.email" />
        </label>
        <InputField type="email" id="email" placeholder="name@example.com" name="email" className="w-full" />
      </div>
      <div className="flex flex-col gap-1.5">
        <label htmlFor="password" id="password" className="flex w-full items-center justify-between text-sm font-medium text-slate-700">
          <span>
            <Trans i18nKey="auth.login.password" />
          </span>
          <ResetPasswordLink />
        </label>
        <InputField type="password" placeholder="••••••••" name="password" className="w-full" />
      </div>

      <SubmitButton type="submit" isSubmitting={isSubmitting} className="u-btn u-btn--primary w-full">
        <Trans i18nKey="auth.login.sign-in" />
      </SubmitButton>
    </Form>

    {error && (
      <div className="flex rounded-xl border border-error-300 bg-error-25 p-4 text-sm text-error-700">
        <div className="mr-3">
          <div className="inline-flex rounded-full border border-error-300 p-0.5 ring-1 ring-error-300 ring-opacity-50 ring-offset-2">
            <FontAwesomeIcon icon={faExclamationCircle} className="h-4 w-auto text-error-600" />
          </div>
        </div>
        <div>
          <div className="font-semibold">
            <Trans i18nKey="auth.login.access-denied" />
          </div>
          <div>{errorMessage ?? <Trans i18nKey="auth.login.access-denied-description" />}</div>
        </div>
      </div>
    )}
  </>
);
