import { forwardRef, PropsWithChildren } from 'react';
import { cn } from '../../../utils';
import { InlineSpinner } from '../InlineSpinner';
import Button from './Button';

type SubmitButtonProps = PropsWithChildren<
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
    isSubmitting?: boolean;
  }
>;

const SubmitButton = forwardRef<HTMLButtonElement, SubmitButtonProps>(({ children, isSubmitting, ...rest }, forwardedRef) => (
  <Button {...rest} ref={forwardedRef} disabled={isSubmitting}>
    <span className={cn('transition-opacity', { 'opacity-0': isSubmitting })}>{children}</span>
    <div className={cn('inset-0 absolute flex items-center justify-center', { 'opacity-0': !isSubmitting })}>
      <InlineSpinner />
    </div>
  </Button>
));
SubmitButton.displayName = 'SubmitButton';

export default SubmitButton;
