import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import { createWhitelistFilter } from 'redux-persist-transform-filter';
import storage from 'redux-persist/lib/storage/session';
import authSlice from './auth.slice';

const rootReducer = combineReducers({
  [authSlice.name]: authSlice.reducer,
});
export type RootState = ReturnType<typeof rootReducer>;

const persistingAuthReducers = createWhitelistFilter(authSlice.name, ['context']);

const persistConfig = {
  key: 'auth',
  storage,
  transforms: [persistingAuthReducers],
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
const persistedReducer = persistReducer(persistConfig, rootReducer as any);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);

export default store;
