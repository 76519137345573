import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from 'react-i18next';
import { Link } from '../../../components/ui';

export const ReturnToSignInLink = () => (
  <div className="mt-6 flex flex-col items-center text-center">
    <p className="text-sm text-slate-500">
      <Link
        to="/auth/login"
        className="u-ring group flex items-center gap-1 rounded px-2 py-1 text-sm font-medium text-slate-600 transition"
      >
        <FontAwesomeIcon icon={faArrowLeft} fixedWidth />
        <span className="underline-offset-4 transition group-hover:underline">
          <Trans i18nKey="auth.return-to-sign-in" />
        </span>
      </Link>
    </p>
  </div>
);
