import { Link } from '../../ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { useCurrentUser } from '../../../store/auth.slice';
import { Helmet } from 'react-helmet-async';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { CreateOrganizationForm } from './CreateOrganizationForm';

type FormValues = {
  name: string;
  email: string;
};

const validationSchema = Yup.object().shape({});

const CreateOrganization = () => {
  const user = useCurrentUser();
  const initialValues: FormValues = {
    name: '',
    email: user.email,
  };

  const submit = () => {
    //
  };

  return (
    <div>
      <Helmet title="Create organization" />

      <div className="text-center">
        <h1 className="mb-2 text-2xl font-semibold text-slate-900">Create an account</h1>
        <p className="mb-6 px-2 text-sm text-slate-500">Create a new organization on Timewave for free.</p>
      </div>

      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit}>
        {(props) => <CreateOrganizationForm error={null} {...props} />}
      </Formik>

      <div className="mt-6 flex flex-col items-center text-center">
        <p className="text-sm text-slate-500">
          <Link
            to="/auth/login"
            className="u-ring group flex items-center gap-1 rounded px-2 py-1 text-sm font-medium text-slate-600 transition"
          >
            <FontAwesomeIcon icon={faArrowLeft} fixedWidth />
            <span className="underline-offset-4 transition group-hover:underline">Return to application</span>
          </Link>
        </p>
      </div>
    </div>
  );
};

export default CreateOrganization;
