import { useField } from 'formik';
import { Trans } from 'react-i18next';
import { Link } from '../../../../components';

export const ResetPasswordLink = () => {
  const [{ value: email }] = useField<string>('email');

  return (
    <Link to={`/auth/reset-password?email=${email}`} className="text-xs font-normal text-primary hover:underline" tabIndex={99}>
      <Trans i18nKey="auth.login.forgot-password" />
    </Link>
  );
};
