import axios from 'axios';
import 'date-fns/locale/en-US';
import 'date-fns/locale/pl';
import React from 'react';
import 'react-day-picker/dist/style.css';
import ReactDOM from 'react-dom/client';
import { App } from './components';
import { config } from './config';
import './css/index.css';
import './i18n';
import { AppProviders } from './providers';

axios.defaults.baseURL = config.apiUrl;

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <AppProviders>
      <App />
    </AppProviders>
  </React.StrictMode>,
);
