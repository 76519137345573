import { Form, FormikProps } from 'formik';
import { InputField } from '../../form';
import { SubmitButton } from '../../ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';

type Props<T> = FormikProps<T> & {
  error: Nullable<string>;
};

export const CreateOrganizationForm = <T,>({ error, isSubmitting }: Props<T>) => (
  <>
    <Form noValidate spellCheck={false} aria-label="Create an organization" className="flex flex-col gap-4 mb-4">
      <div className="flex flex-col gap-1.5">
        <label htmlFor="email" className="w-full text-sm font-medium text-slate-700">
          Organization name
        </label>
        <InputField type="text" id="name" placeholder="" name="name" className="w-full" />
      </div>

      <div className="flex flex-col gap-1.5">
        <label htmlFor="email" className="w-full text-sm font-medium text-slate-700">
          Administrator's Email Address
        </label>
        <InputField type="email" id="email" placeholder="name@example.com" name="email" className="w-full" />
      </div>

      <SubmitButton type="submit" isSubmitting={isSubmitting} className="w-full u-btn u-btn--primary">
        Create organization
      </SubmitButton>
    </Form>

    {error && (
      <div className="flex p-4 text-sm border bg-error-25 text-error-700 border-error-300 rounded-xl">
        <div className="mr-3">
          <div className="inline-flex p-0.5 border rounded-full border-error-300 ring-1 ring-offset-2 ring-opacity-50 ring-error-300">
            <FontAwesomeIcon icon={faExclamationCircle} className="w-auto h-4 text-error-600" />
          </div>
        </div>
        <div>
          <div className="font-semibold">Organization could not be created</div>
          <div>{error}</div>
        </div>
      </div>
    )}
  </>
);
