import { useButton } from '@react-aria/button';
import { FocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import { AriaButtonProps } from '@react-types/button';
import { forwardRef, PropsWithChildren, useRef } from 'react';
import { mergeRefs } from 'react-merge-refs';
import { Tooltip } from '../Tooltip';

export type ButtonProps = PropsWithChildren<
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
    tooltip?: string;
  }
>;

const Button = forwardRef<HTMLButtonElement, ButtonProps>(({ children, className, onClick, tooltip, ...props }, forwardedRef) => {
  const localRef = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(
    mergeProps(props, {
      isDisabled: props.disabled,
      onPress: onClick,
    }) as AriaButtonProps,
    localRef,
  );

  const InnerButton = (
    <button {...buttonProps} ref={mergeRefs([localRef, forwardedRef])} className={className}>
      {children}
    </button>
  );

  return <FocusRing focusRingClass="u-focus-ring">{tooltip ? <Tooltip title={tooltip}>{InnerButton}</Tooltip> : InnerButton}</FocusRing>;
});
Button.displayName = 'Button';

export default Button;
